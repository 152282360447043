import { useState } from 'react'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { Input, RadioGroup } from '@x10/lib-ui-kit/components'

enum LeaderboardPrivacy {
  ShowWalletAddress = 'SHOW_WALLET_ADDRESS',
  ShowUsername = 'SHOW_USERNAME',
  ShowTwitter = 'SHOW_TWITTER',
}

export const UserSettingsPrivacy = () => {
  const { formatMessage } = useIntl()
  const [leaderboardPrivacy, setLeaderboardPrivacy] = useState(
    LeaderboardPrivacy.ShowWalletAddress,
  )

  return (
    <div>
      <Box css={{ textStyle: 'caption', my: 's-16' }}>
        <FormattedMessage
          id="core.component.user-settings-dialog.tab.privacy.leaderboard.title"
          defaultMessage="Leaderboard Preferences"
        />
      </Box>

      <RadioGroup.Root
        value={leaderboardPrivacy}
        onValueChange={({ value }) => {
          setLeaderboardPrivacy(value as LeaderboardPrivacy)
        }}
      >
        <RadioGroup.Item value={LeaderboardPrivacy.ShowWalletAddress}>
          <RadioGroup.ItemControl />
          <RadioGroup.ItemText>
            <RadioGroup.ItemLabel>
              <FormattedMessage
                id="core.component.user-settings-dialog.tab.privacy.leaderboard.item.wallet-address.title"
                defaultMessage="Show Wallet Address"
              />
            </RadioGroup.ItemLabel>

            <RadioGroup.ItemDescription>
              <FormattedMessage
                id="core.component.user-settings-dialog.tab.privacy.leaderboard.item.wallet-address.description"
                defaultMessage="Disable if you prefer your wallet address to not be visible in the Leaderboard"
              />
            </RadioGroup.ItemDescription>
          </RadioGroup.ItemText>
          <RadioGroup.ItemHiddenInput />
        </RadioGroup.Item>

        <RadioGroup.Item value={LeaderboardPrivacy.ShowUsername}>
          <RadioGroup.ItemControl />
          <RadioGroup.ItemText>
            <RadioGroup.ItemLabel>
              <FormattedMessage
                id="core.component.user-settings-dialog.tab.privacy.leaderboard.item.username.title"
                defaultMessage="Show Username"
              />
            </RadioGroup.ItemLabel>
          </RadioGroup.ItemText>
          <RadioGroup.ItemHiddenInput />
        </RadioGroup.Item>

        <RadioGroup.Item value={LeaderboardPrivacy.ShowTwitter}>
          <RadioGroup.ItemControl />
          <RadioGroup.ItemText>
            <RadioGroup.ItemLabel>
              <FormattedMessage
                id="core.component.user-settings-dialog.tab.privacy.leaderboard.item.twitter.title"
                defaultMessage="Show X (Twitter)"
              />
            </RadioGroup.ItemLabel>
          </RadioGroup.ItemText>
          <RadioGroup.ItemHiddenInput />
        </RadioGroup.Item>
      </RadioGroup.Root>

      <Box css={{ mt: 's-24' }}>
        <Input
          disabled={leaderboardPrivacy !== LeaderboardPrivacy.ShowTwitter}
          label={formatMessage({
            id: 'core.component.user-settings-dialog.tab.privacy.leaderboard.input.twitter.title',
            defaultMessage: 'X (Twitter)',
          })}
          value="https://x.com/ruslanx"
        />
      </Box>
    </div>
  )
}
