import { type FC } from 'react'

import { FormattedMessage, type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Menu } from '@x10/lib-ui-kit/components'

import {
  API_DOCS_URL,
  DISCORD_URL,
  LANDING_URL,
  MAINNET_URL,
  TELEGRAM_URL,
  TESTNET_URL,
  TWITTER_URL,
} from '@src/domain/core/config/static'
import { NavigationLink } from '@src/domain/core/ui/components/navigation-link'
import {
  asExternalRoute,
  documentationRoute,
  homeRoute,
} from '@src/domain/core/utils/routes'

const ID_TO_URL = {
  portfolioOverview: homeRoute({}).portfolio({}).overview({}).$,
  portfolioPositions: homeRoute({}).portfolio({}).positions({}).$,
  portfolioOrders: homeRoute({}).portfolio({}).orders({}).$,
  portfolioRealisedPnl: homeRoute({}).portfolio({}).realisedPnl({}).$,
  portfolioTrades: homeRoute({}).portfolio({}).trades({}).$,
  portfolioFunding: homeRoute({}).portfolio({}).funding({}).$,
  portfolioTransfers: homeRoute({}).portfolio({}).transfers({}).$,

  moreDocumentation: asExternalRoute(documentationRoute({}).$),
  moreApiManagement: homeRoute({}).more({}).apiManagement({}).$,
  moreApiDocumentation: API_DOCS_URL,
  moreLegal: asExternalRoute(documentationRoute({}).legal({}).$),
  moreTestnet: TESTNET_URL,
  moreMainnet: MAINNET_URL,
  moreWebsite: LANDING_URL,
  moreDiscord: DISCORD_URL,
  moreXTwitter: TWITTER_URL,
  moreTelegram: TELEGRAM_URL,
  moreNews: homeRoute({}).more({}).news({}).$,
  moreCommunityLinks: homeRoute({}).more({}).communityLinks({}).$,
  moreStatistics: homeRoute({}).more({}).statistics({}).$,
  moreTechStatus: homeRoute({}).more({}).techStatus({}).$,

  helpHelpCenter: homeRoute({}).help({}).helpCenter({}).$,
} as const

type MenuSubItemId = keyof typeof ID_TO_URL

export const getItemIntlKey = (value: MenuSubItemId): MessageDescriptor => {
  switch (value) {
    case 'portfolioOverview':
      return {
        id: 'header.item.portfolio.subitem.overview.title',
        defaultMessage: 'Overview',
      }
    case 'portfolioPositions':
      return {
        id: 'header.item.portfolio.subitem.positions.title',
        defaultMessage: 'Positions',
      }
    case 'portfolioOrders':
      return {
        id: 'header.item.portfolio.subitem.orders.title',
        defaultMessage: 'Orders',
      }
    case 'portfolioRealisedPnl':
      return {
        id: 'header.item.portfolio.subitem.realised-pnl.title',
        defaultMessage: 'Realised PnL',
      }
    case 'portfolioTrades':
      return {
        id: 'header.item.portfolio.subitem.trades.title',
        defaultMessage: 'Trades',
      }
    case 'portfolioFunding':
      return {
        id: 'header.item.portfolio.subitem.funding.title',
        defaultMessage: 'Funding',
      }
    case 'portfolioTransfers':
      return {
        id: 'header.item.portfolio.subitem.transfers.title',
        defaultMessage: 'Transfers',
      }

    case 'moreApiManagement':
      return {
        id: 'header.item.more.subitem.api-management.title',
        defaultMessage: 'API Management',
      }
    case 'moreApiDocumentation':
      return {
        id: 'header.item.more.subitem.api-documentation.title',
        defaultMessage: 'API Documentation',
      }
    case 'moreDocumentation':
      return {
        id: 'header.item.more.subitem.documentation.title',
        defaultMessage: 'Documentation',
      }
    case 'moreNews':
      return {
        id: 'header.item.more.subitem.news.title',
        defaultMessage: 'News',
      }
    case 'moreCommunityLinks':
      return {
        id: 'header.item.more.subitem.community-links.title',
        defaultMessage: 'Community Links',
      }
    case 'moreStatistics':
      return {
        id: 'header.item.more.subitem.statistics.title',
        defaultMessage: 'Statistics',
      }
    case 'moreTechStatus':
      return {
        id: 'header.item.more.subitem.tech-status.title',
        defaultMessage: 'Tech Status',
      }
    case 'moreLegal':
      return {
        id: 'header.item.icon-help.subitem.legal.title',
        defaultMessage: 'Legal Documents',
      }
    case 'moreDiscord':
      return {
        id: 'header.item.more.subitem.discord.title',
        defaultMessage: 'Discord',
      }
    case 'moreXTwitter':
      return {
        id: 'header.item.more.subitem.twitter.title',
        defaultMessage: 'X (Twitter)',
      }
    case 'moreTelegram':
      return {
        id: 'header.item.more.subitem.telegram.title',
        defaultMessage: 'Telegram',
      }
    case 'moreWebsite':
      return {
        id: 'header.item.more.subitem.website.title',
        defaultMessage: 'Website',
      }
    case 'moreTestnet':
      return {
        id: 'header.item.more.subitem.testnet.title',
        defaultMessage: 'Testnet',
      }
    case 'moreMainnet':
      return {
        id: 'header.item.more.subitem.mainnet.title',
        defaultMessage: 'Mainnet',
      }

    case 'helpHelpCenter':
      return {
        id: 'header.item.icon-help.subitem.help-center.title',
        defaultMessage: 'Help Center & FAQ',
      }
    default:
      throw notReachable(value)
  }
}

type HeaderMenuSubItemProps = {
  id: MenuSubItemId
  openInNewTab?: boolean
}

export const HeaderMenuSubItem: FC<HeaderMenuSubItemProps> = ({
  id,
  openInNewTab = true,
}) => {
  return (
    <Menu.Item value={id} display="block" asChild>
      <NavigationLink url={ID_TO_URL[id]} openInNewTab={openInNewTab}>
        <FormattedMessage {...getItemIntlKey(id)} />
      </NavigationLink>
    </Menu.Item>
  )
}
