import { FormattedMessage } from '@x10/lib-core/i18n'
import { Cell } from '@x10/lib-ui-kit/components'

import { useClientSettingsStore } from '@src/domain/core/store/user-settings'
import type { AllowedNotificationsGroup } from '@src/domain/core/store/user-settings/user-settings'

import { SwitchCell } from './switch-cell'

const ALL_NOTIFICATIONS: AllowedNotificationsGroup[] = [
  'ORDERS_AND_TRADES',
  'LIQUIDATIONS',
  'TX_AND_ACCOUNTS',
]

export const UserSettingsNotifications = () => {
  const allowedNotifications = useClientSettingsStore(
    (state) => state.allowedNotifications,
  )
  const setAllowedNotifications = useClientSettingsStore(
    (state) => state.setAllowedNotifications,
  )

  const handleSettingChange = (
    group: 'ALL' | AllowedNotificationsGroup,
    value: boolean,
  ) => {
    if (group === 'ALL') {
      setAllowedNotifications(value ? undefined : [])
      return
    }

    const nextValue = allowedNotifications ?? ALL_NOTIFICATIONS

    setAllowedNotifications(
      value ? nextValue.concat(group) : nextValue.filter((item) => item !== group),
    )
  }

  const isAllAllowed = !allowedNotifications

  return (
    <div>
      <Cell.Group css={{ gap: 's-16', mt: 's-16' }}>
        <SwitchCell.Root
          checked={isAllAllowed || allowedNotifications.length !== 0}
          onChange={(value) => handleSettingChange('ALL', value)}
        >
          <SwitchCell.Title>
            <FormattedMessage
              id="core.component.user-settings-dialog.tab.notifications.allow-notifications.title"
              defaultMessage="Allow Notifications"
            />
          </SwitchCell.Title>
        </SwitchCell.Root>

        <SwitchCell.Root
          css={{ pl: 's-16' }}
          checked={isAllAllowed || allowedNotifications.includes('ORDERS_AND_TRADES')}
          onChange={(value) => handleSettingChange('ORDERS_AND_TRADES', value)}
        >
          <SwitchCell.Title>
            <FormattedMessage
              id="core.component.user-settings-dialog.tab.notifications.orders-and-trades.title"
              defaultMessage="Orders & Trades"
            />
          </SwitchCell.Title>

          <SwitchCell.Description>
            <FormattedMessage
              id="core.component.user-settings-dialog.tab.notifications.orders-and-trades.description"
              defaultMessage="Status changes, trade sizes and prices"
            />
          </SwitchCell.Description>
        </SwitchCell.Root>

        <SwitchCell.Root
          css={{ pl: 's-16' }}
          checked={isAllAllowed || allowedNotifications.includes('LIQUIDATIONS')}
          onChange={(value) => handleSettingChange('LIQUIDATIONS', value)}
        >
          <SwitchCell.Title>
            <FormattedMessage
              id="core.component.user-settings-dialog.tab.notifications.liquidations.title"
              defaultMessage="Liquidations"
            />
          </SwitchCell.Title>

          <SwitchCell.Description>
            <FormattedMessage
              id="core.component.user-settings-dialog.tab.notifications.liquidations.description"
              defaultMessage="Margin calls, liquidations and ADL trades"
            />
          </SwitchCell.Description>
        </SwitchCell.Root>

        <SwitchCell.Root
          css={{ pl: 's-16' }}
          checked={isAllAllowed || allowedNotifications.includes('TX_AND_ACCOUNTS')}
          onChange={(value) => handleSettingChange('TX_AND_ACCOUNTS', value)}
        >
          <SwitchCell.Title>
            <FormattedMessage
              id="core.component.user-settings-dialog.tab.notifications.transactions-and-accounts.title"
              defaultMessage="Transactions & Accounts"
            />
          </SwitchCell.Title>

          <SwitchCell.Description>
            <FormattedMessage
              id="core.component.user-settings-dialog.tab.notifications.transactions-and-accounts.description"
              defaultMessage="Deposits, withdrawals and sub-accounts"
            />
          </SwitchCell.Description>
        </SwitchCell.Root>
      </Cell.Group>
    </div>
  )
}
