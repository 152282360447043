import { Suspense, type FC } from 'react'
import dynamic from 'next/dynamic'

import { useIntl } from '@x10/lib-core/i18n'
import { type Locale } from '@x10/lib-core/types'
import { getAssetUrl } from '@x10/lib-core/utils'
import { HStack } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  LanguageSelector,
  Logo,
  OnlineStatus,
  SvgIcon,
} from '@x10/lib-ui-kit/components'
import { useDisclosure } from '@x10/lib-ui-kit/hooks'

import { LoggedInGuard } from '@src/domain/auth/components/logged-in-guard'
import { useGlobalSettingsStore } from '@src/domain/core/store/user-settings'
import { UserSettingsDialog } from '@src/domain/core/ui/components/dialog/user-settings-dialog'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'

import { HelpItem } from './components/help-item'
import { NotificationsItem } from './components/notifications-item'
import { SupportItem } from './components/support-item'

const WalletItem = dynamic(
  () =>
    import('@src/domain/auth/components/wallet-item').then((module) => module.WalletItem),
  {
    ssr: false,
  },
)

const DesktopItems: FC = () => {
  const { formatMessage } = useIntl()
  const settingsDialogDisclosure = useDisclosure()

  const currentLocale = useGlobalSettingsStore((state) => state.locale)
  const setCurrentLocale = useGlobalSettingsStore((state) => state.setLocale)

  const handleLocaleClick = (newLocale: Locale) => {
    setCurrentLocale(newLocale)
  }

  return (
    <>
      <FeatureGate name="HEADER_ONLINE">
        <OnlineStatus />
      </FeatureGate>

      <FeatureGate name="HEADER_LANG_SELECTOR">
        <LanguageSelector locale={currentLocale} onChange={handleLocaleClick} />
      </FeatureGate>

      <LoggedInGuard>
        <ActionIcon
          title={formatMessage({
            id: 'header.item.icon-settings.title',
            defaultMessage: 'Settings',
          })}
          onClick={settingsDialogDisclosure.onOpen}
        >
          <SvgIcon.SvgIconControls />
        </ActionIcon>
      </LoggedInGuard>

      <FeatureGate name="HEADER_NOTIFICATIONS">
        <NotificationsItem />
      </FeatureGate>

      <FeatureGate name="HEADER_HELP">
        <HelpItem />
      </FeatureGate>

      <Suspense fallback={null}>
        <SupportItem />
      </Suspense>

      <WalletItem />
      <UserSettingsDialog
        open={settingsDialogDisclosure.isOpen}
        onClose={settingsDialogDisclosure.onClose}
      />
    </>
  )
}

const MobileItems: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <>
      <ActionIcon
        title={formatMessage({
          id: 'header.item.icon-notifications.title',
          defaultMessage: 'Notifications',
        })}
      >
        <SvgIcon.SvgIconBell />
      </ActionIcon>

      <Logo
        url={getAssetUrl({ type: 'avatar', name: 'pixel-positive.svg' })}
        boxSize={24}
      />
    </>
  )
}

export const HeaderRightItems = () => {
  return (
    <>
      <HStack gap="s-24" hideBelow="md">
        <DesktopItems />
      </HStack>

      <FeatureGate name="MOBILE_HEADER_ITEMS">
        <HStack gap="s-24" hideFrom="md">
          <MobileItems />
        </HStack>
      </FeatureGate>
    </>
  )
}
