import type { FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { HStack } from '@x10/lib-styled-system/jsx'
import { Dialog, Portal, Tabs } from '@x10/lib-ui-kit/components'

import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'

import { DialogCloseTrigger } from '../dialog-close-trigger'
import { DialogContent } from '../dialog-content'
import { UserSettingsNotifications } from './user-settings-notifications'
import { UserSettingsPrivacy } from './user-settings-privacy'

enum SettingsTab {
  Notifications = 'NOTIFICATIONS',
  Privacy = 'PRIVACY',
}

export const UserSettingsDialog: FC<{ open?: boolean; onClose: VoidFunction }> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog.Root
      modal
      lazyMount
      unmountOnExit
      open={open}
      onOpenChange={(value) => {
        if (!value.open) {
          onClose()
        }
      }}
    >
      <Portal>
        <Dialog.Backdrop />

        <Dialog.Positioner>
          <DialogContent variant="wide">
            <Tabs.Root size="lg" defaultValue={SettingsTab.Notifications}>
              <HStack css={{ gap: 0, justifyContent: 'space-between' }}>
                <Tabs.List>
                  <Tabs.Trigger value={SettingsTab.Notifications}>
                    <FormattedMessage
                      id="core.component.user-settings-dialog.tab.notifications.title"
                      defaultMessage="Notifications"
                    />
                  </Tabs.Trigger>

                  <FeatureGate name="USER_MENU_PRIVACY_AND_SECURITY">
                    <Tabs.Trigger value={SettingsTab.Privacy}>
                      <FormattedMessage
                        id="core.component.user-settings-dialog.tab.privacy.title"
                        defaultMessage="Privacy"
                      />
                    </Tabs.Trigger>
                  </FeatureGate>
                </Tabs.List>
                <DialogCloseTrigger />
              </HStack>

              <Tabs.Content value={SettingsTab.Notifications}>
                <UserSettingsNotifications />
              </Tabs.Content>

              <Tabs.Content value={SettingsTab.Privacy}>
                <UserSettingsPrivacy />
              </Tabs.Content>
            </Tabs.Root>
          </DialogContent>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
