import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Menu, Portal } from '@x10/lib-ui-kit/components'

import { getClientConfig } from '@src/domain/core/config/env'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'

import { HeaderMenuItemWithSubItems } from '../header-menu-item-with-sub-items'
import { HeaderMenuSubItem } from '../header-menu-sub-item'

export const MoreItem: FC = () => {
  const config = getClientConfig()

  return (
    <Menu.Root>
      <Menu.Context>
        {({ open }) => {
          return (
            <>
              <Menu.Trigger asChild>
                <HeaderMenuItemWithSubItems open={open}>
                  <FormattedMessage id="header.item.more.title" defaultMessage="More" />
                </HeaderMenuItemWithSubItems>
              </Menu.Trigger>

              <Portal>
                <Menu.Positioner>
                  <Menu.Content minW="9.375rem">
                    <HeaderMenuSubItem id="moreDocumentation" />
                    <HeaderMenuSubItem id="moreApiManagement" openInNewTab={false} />
                    <HeaderMenuSubItem id="moreApiDocumentation" />
                    <HeaderMenuSubItem id="moreLegal" />
                    {!config.isTestnetRuntimeEnv && (
                      <HeaderMenuSubItem id="moreTestnet" />
                    )}
                    {!config.isProdRuntimeEnv && <HeaderMenuSubItem id="moreMainnet" />}
                    <HeaderMenuSubItem id="moreWebsite" />
                    <HeaderMenuSubItem id="moreDiscord" />
                    <HeaderMenuSubItem id="moreXTwitter" />
                    <HeaderMenuSubItem id="moreTelegram" />

                    <FeatureGate name="HEADER_MORE">
                      <HeaderMenuSubItem id="moreNews" />
                      <HeaderMenuSubItem id="moreCommunityLinks" />
                      <HeaderMenuSubItem id="moreStatistics" />
                      <HeaderMenuSubItem id="moreTechStatus" />
                    </FeatureGate>
                  </Menu.Content>
                </Menu.Positioner>
              </Portal>
            </>
          )
        }}
      </Menu.Context>
    </Menu.Root>
  )
}
