import { type FC } from 'react'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { ActionIcon, Menu, Portal, SvgIcon } from '@x10/lib-ui-kit/components'

import { HeaderMenuSubItem } from '../header-menu-sub-item'

export const HelpItem: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Menu.Root lazyMount>
      <Menu.Context>
        {(api) => {
          return (
            <>
              <Menu.Trigger asChild>
                <ActionIcon
                  title={formatMessage({
                    id: 'header.item.icon-help.title',
                    defaultMessage: 'Help',
                  })}
                  active={api.open}
                >
                  <SvgIcon.SvgIconHelp />
                </ActionIcon>
              </Menu.Trigger>

              <Portal>
                <Menu.Positioner>
                  <Menu.Content minW="9.375rem">
                    <HeaderMenuSubItem id="helpHelpCenter" />

                    <Menu.Item value="report-bug">
                      <FormattedMessage
                        id="header.item.icon-help.subitem.report-bug.title"
                        defaultMessage="Report a bug"
                      />
                    </Menu.Item>

                    <Menu.Item value="provide-feedback">
                      <FormattedMessage
                        id="header.item.icon-help.subitem.provide-feedback.title"
                        defaultMessage="Provide Feedback"
                      />
                    </Menu.Item>
                  </Menu.Content>
                </Menu.Positioner>
              </Portal>
            </>
          )
        }}
      </Menu.Context>
    </Menu.Root>
  )
}
