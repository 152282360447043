import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { useIntl } from '@x10/lib-core/i18n'
import { ActionIcon, SvgIcon } from '@x10/lib-ui-kit/components'

import { useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { getClientConfig } from '@src/domain/core/config/env'
import { DISCORD_URL } from '@src/domain/core/config/static'
import * as intercom from '@src/domain/core/utils/intercom'
import { openExternalLink } from '@src/domain/core/utils/open-external-link'

export const SupportItem = () => {
  const { formatMessage } = useIntl()
  const { address } = useAccount()
  const isLoggedIn = useSuspendedIsLoggedIn()

  const [isIntercomVisible, setIntercomVisible] = useState(false)

  const intercomAppId = getClientConfig().app.intercomAppId

  useEffect(() => {
    if (!address || !isLoggedIn || !intercomAppId) {
      return
    }

    intercom.load(intercomAppId)
    intercom.boot({
      user_id: address,
    })

    setIntercomVisible(true)
  }, [address, isLoggedIn, intercomAppId])

  if (isIntercomVisible) {
    return (
      <ActionIcon
        title={formatMessage({
          id: 'header.item.icon-chat.title',
          defaultMessage: 'Support',
        })}
        id={intercom.INTERCOM_CHAT_BUTTON_ID}
      >
        <SvgIcon.SvgIconChat size={20} />
      </ActionIcon>
    )
  }

  return (
    <ActionIcon
      title={formatMessage({
        id: 'header.item.icon-discord.title',
        defaultMessage: 'Ask in Discord',
      })}
      onClick={() => openExternalLink(DISCORD_URL)}
    >
      <SvgIcon.SvgIconDiscord size={20} />
    </ActionIcon>
  )
}
