import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Menu, Portal } from '@x10/lib-ui-kit/components'

import { HeaderMenuItemWithSubItems } from '../header-menu-item-with-sub-items'
import { HeaderMenuSubItem } from '../header-menu-sub-item'

export const PortfolioItem: FC = () => {
  return (
    <Menu.Root lazyMount>
      <Menu.Context>
        {(api) => {
          return (
            <>
              <Menu.Trigger asChild>
                <HeaderMenuItemWithSubItems open={api.open}>
                  <FormattedMessage
                    id="header.item.portfolio.title"
                    defaultMessage="Portfolio"
                  />
                </HeaderMenuItemWithSubItems>
              </Menu.Trigger>

              <Portal>
                <Menu.Positioner>
                  <Menu.Content minW="9.375rem">
                    <HeaderMenuSubItem id="portfolioOverview" />
                    <HeaderMenuSubItem id="portfolioPositions" />
                    <HeaderMenuSubItem id="portfolioOrders" />
                    <HeaderMenuSubItem id="portfolioRealisedPnl" />
                    <HeaderMenuSubItem id="portfolioPositions" />
                    <HeaderMenuSubItem id="portfolioTrades" />
                    <HeaderMenuSubItem id="portfolioFunding" />
                    <HeaderMenuSubItem id="portfolioTransfers" />
                  </Menu.Content>
                </Menu.Positioner>
              </Portal>
            </>
          )
        }}
      </Menu.Context>
    </Menu.Root>
  )
}
