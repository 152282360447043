import { Decimal } from '@x10/lib-core/utils'
import { Box, VStack } from '@x10/lib-styled-system/jsx'
import { FormattedRelativeDate } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import {
  NotificationDepositDetected,
  NotificationTradeWithAmounts,
  NotificationWithdraw,
} from '@src/domain/core/ui/components/notification'

export const NotificationSide = () => {
  return (
    <VStack p="s-16" gap="s-16" w="100%" alignItems="start">
      <Box textStyle="primary">Notifications</Box>
      <Box textStyle="caption">
        <FormattedRelativeDate value={Date.now()} />
      </Box>
      <NotificationDepositDetected amount={50_000} />
      <NotificationTradeWithAmounts
        collateralPrice={Decimal(50_000)}
        syntheticAmount={Decimal(50_000)}
        direction="BUY"
        event="expired"
        marketName={'BTCUSD' as MarketName}
        orderType={'MARKET'}
      />
      <NotificationTradeWithAmounts
        collateralPrice={Decimal(50_000)}
        syntheticAmount={Decimal(50_000)}
        direction="BUY"
        event="filled"
        marketName={'BTCUSD' as MarketName}
        orderType={'MARKET'}
      />
      <NotificationTradeWithAmounts
        collateralPrice={Decimal(50_000)}
        syntheticAmount={Decimal(50_000)}
        direction="BUY"
        event="filled"
        marketName={'BTCUSD' as MarketName}
        orderType={'MARKET'}
      />
      <Box textStyle="caption">
        <FormattedRelativeDate value={Date.parse('2023-10-15')} />
      </Box>
      <NotificationTradeWithAmounts
        collateralPrice={Decimal(50_000)}
        syntheticAmount={Decimal(50_000)}
        direction="BUY"
        event="filled"
        marketName={'BTCUSD' as MarketName}
        orderType={'MARKET'}
      />
      <NotificationTradeWithAmounts
        collateralPrice={Decimal(50_000)}
        syntheticAmount={Decimal(50_000)}
        direction="BUY"
        event="filled"
        marketName={'BTCUSD' as MarketName}
        orderType={'MARKET'}
      />
      <Box textStyle="caption">
        <FormattedRelativeDate value={Date.parse('2022-07-22')} />
      </Box>
      <NotificationWithdraw state="completed" amount={Decimal(50_000)} />
    </VStack>
  )
}
