import { useIntl } from '@x10/lib-core/i18n'
import {
  ActionIcon,
  Popover,
  Portal,
  Scrollable,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { NotificationSide } from '@src/domain/core/ui/components/notification-side'

export const NotificationsItem = () => {
  const { formatMessage } = useIntl()

  return (
    <Popover.Root portalled positioning={{ placement: 'bottom' }}>
      <Popover.Context>
        {({ open }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <ActionIcon
                  title={formatMessage({
                    id: 'header.item.icon-notifications.title',
                    defaultMessage: 'Notifications',
                  })}
                  active={open}
                >
                  <SvgIcon.SvgIconBell />
                </ActionIcon>
              </Popover.Trigger>

              <Portal>
                <Popover.Positioner
                  top="var(--y)!"
                  left="unset!"
                  right="s-2"
                  bottom="s-2"
                  transform="translate3d(0, 0, 0)!"
                >
                  <Popover.Content borderRadius="r-16">
                    <Scrollable scrollY>
                      <NotificationSide />
                    </Scrollable>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
