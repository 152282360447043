import { type FC, type PropsWithChildren } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Center } from '@x10/lib-styled-system/jsx'
import { Link } from '@x10/lib-ui-kit/components'

import { getClientConfig } from '@src/domain/core/config/env'
import { DISCORD_URL } from '@src/domain/core/config/static'

const BannerWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Link href={DISCORD_URL} external>
      <Center
        css={{
          bgColor: 'token.green-10',
          color: 'token.green',
          textStyle: 'small',
          h: '1.375rem',
          w: '100%',

          _hover: {
            color: 'token.green-90',
          },
        }}
      >
        {children}
      </Center>
    </Link>
  )
}

export const ProductInDevelopmentBanner: FC = () => {
  const config = getClientConfig()

  if (config.isProdRuntimeEnv) {
    return (
      <BannerWrapper>
        <FormattedMessage
          id="header.product-in-development-banner.prod.title"
          defaultMessage="Please be aware that we are still in the process of enhancing the product. As such, some features may not work as expected. We encourage you to report any errors or unexpected behavior in our Discord channel."
        />
      </BannerWrapper>
    )
  }

  return (
    <BannerWrapper>
      <FormattedMessage
        id="header.product-in-development-banner.testnet.title"
        defaultMessage="Please be mindful that this is a test version of the product and we are still in the process of development. As such, some features may not work as expected. Please report any errors or unexpected behavior in our Discord."
      />
    </BannerWrapper>
  )
}
