import { type FC } from 'react'
import NextLink from 'next/link'
import { usePathname } from 'next/navigation'

import {
  NavigationLink as KitNavigationLink,
  type NavigationLinkProps as KitNavigationLinkProps,
} from '@x10/lib-ui-kit/components'

type NavigationLinkProps = Omit<KitNavigationLinkProps, 'pathname' | 'RouterLink'> & {
  openInNewTab?: boolean
}

export const NavigationLink: FC<NavigationLinkProps> = ({
  url,
  home,
  disabled,
  openInNewTab,
  children,
  ...restProps
}) => {
  const pathname = usePathname()

  return (
    <KitNavigationLink
      url={url}
      pathname={pathname}
      home={home}
      disabled={disabled}
      target={openInNewTab ? '_blank' : undefined}
      RouterLink={NextLink}
      {...restProps}
    >
      {children}
    </KitNavigationLink>
  )
}
