import type { FC, PropsWithChildren } from 'react'

import { css } from '@x10/lib-styled-system/css'
import { Box } from '@x10/lib-styled-system/jsx'
import type { WithCss } from '@x10/lib-styled-system/types'
import { Cell, Switch } from '@x10/lib-ui-kit/components'

const Title: FC<PropsWithChildren> = ({ children }) => {
  return <Box>{children}</Box>
}

const Description: FC<PropsWithChildren> = ({ children }) => {
  return <Box css={{ color: 'token.white-50', fontWeight: 'fw-400' }}>{children}</Box>
}

type RootProps = {
  checked?: boolean
  onChange: (value: boolean) => void
} & WithCss

const Root: FC<PropsWithChildren<RootProps>> = (props) => {
  return (
    <Switch.Root
      checked={props.checked}
      css={css.raw({ w: '100%' }, props.css)}
      onCheckedChange={(value) => {
        props.onChange(value.checked)
      }}
    >
      <Cell.Item>
        <Cell.Title>
          <Switch.Label>{props.children}</Switch.Label>
        </Cell.Title>

        <Cell.Value>
          <Switch.Control>
            <Switch.Thumb />
          </Switch.Control>
        </Cell.Value>
      </Cell.Item>

      <Switch.HiddenInput />
    </Switch.Root>
  )
}

export const SwitchCell = {
  Root,
  Title,
  Description,
}
