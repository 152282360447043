import { forwardRef, type PropsWithChildren } from 'react'

import { styled } from '@x10/lib-styled-system/jsx'

type HeaderMenuItemWithSubItemsProps = PropsWithChildren<{
  open?: boolean
}>

export const HeaderMenuItemWithSubItems = forwardRef<
  HTMLButtonElement,
  HeaderMenuItemWithSubItemsProps
>(({ open, children, ...restProps }, ref) => {
  return (
    <styled.button
      ref={ref}
      color={open ? 'token.white' : 'token.white-50'}
      cursor="pointer"
      _hover={{ color: 'token.white' }}
      {...restProps}
    >
      {children}
    </styled.button>
  )
})

HeaderMenuItemWithSubItems.displayName = 'HeaderMenuItemWithSubItems'
