import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { SvgIcon } from '@x10/lib-ui-kit/components'

import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { NavigationLink } from '@src/domain/core/ui/components/navigation-link'
import { homeRoute } from '@src/domain/core/utils/routes'

import { MoreItem } from './components/more-item'
import { PortfolioItem } from './components/portfolio-item'

const DesktopItems: FC = () => {
  return (
    <>
      <NavigationLink home url={homeRoute({}).trade({}).$}>
        <FormattedMessage id="header.item.trade.title" defaultMessage="Trade" />
      </NavigationLink>

      <NavigationLink url={homeRoute({}).markets({}).$}>
        <FormattedMessage id="header.item.markets.title" defaultMessage="Markets" />
      </NavigationLink>

      <NavigationLink
        url={homeRoute({}).leaderboard({}).$}
        css={{
          backgroundClip: 'text',
          color: 'transparent',
          animation: 'gradientAnimation 15s linear infinite',
          backgroundPosition: '0',
          backgroundSize: '400% 100%',
          _hover: {
            color: 'token.white-50',
          },
        }}
        style={{
          backgroundImage:
            'linear-gradient(90deg, #0aae4e 0%, #0061ff 20%, #0aae4e 40%, #0aae4e 60%, #0061ff 80%, #0aae4e 100%)',
        }}
      >
        <FormattedMessage
          id="header.item.leaderboard.title"
          defaultMessage="Leaderboard"
        />
      </NavigationLink>

      <FeatureGate name="HEADER_PORTFOLIO">
        <PortfolioItem />
      </FeatureGate>

      <FeatureGate name="HEADER_REFERRALS">
        <NavigationLink url={homeRoute({}).referrals({}).$}>
          <FormattedMessage id="header.item.referrals.title" defaultMessage="Referrals" />
        </NavigationLink>
      </FeatureGate>

      <MoreItem />
    </>
  )
}

const MobileItems: FC = () => {
  return <SvgIcon.SvgIconMenu />
}

export const HeaderLeftItems: FC = () => {
  return (
    <>
      <HStack gap="s-16" hideBelow="md">
        <DesktopItems />
      </HStack>

      <FeatureGate name="MOBILE_HEADER_ITEMS">
        <Box hideFrom="md" color="token.white-50">
          <MobileItems />
        </Box>
      </FeatureGate>
    </>
  )
}
