import { Flex, VStack } from '@x10/lib-styled-system/jsx'
import { Header as KitHeader, Link } from '@x10/lib-ui-kit/components'

import { homeRoute } from '@src/domain/core/utils/routes'

import { ProductInDevelopmentBanner } from './components/product-in-development-banner'
import { HeaderLeftItems } from './header-left-items'
import { HeaderRightItems } from './header-right-items'

export const Header = () => {
  return (
    <KitHeader.Root
      css={{
        display: 'block',
        py: 'unset!',
        px: 'unset!',
        h: 'unset!',
      }}
    >
      <VStack
        css={{
          gap: 0,
        }}
      >
        <ProductInDevelopmentBanner />
        <Flex
          css={{
            w: '100%',
            px: 's-16',
            py: 's-24',
            h: '4.5rem',
            justifyContent: 'space-between',
          }}
        >
          <HeaderLeftItems />
          <Link
            href={homeRoute({}).trade({}).$}
            css={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
          >
            <KitHeader.Logo />
          </Link>
          <HeaderRightItems />
        </Flex>
      </VStack>
    </KitHeader.Root>
  )
}
